import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)
Array.prototype.unique = function() {
  var a = this.concat();
  for(var i=0; i<a.length; ++i) {
    for(var j=i+1; j<a.length; ++j) {
      if(a[i] === a[j])
        a.splice(j--, 1);
    }
  }

  return a;
};
const ifAuth = (to, from, next) => {
  let role = store.getters.getRole

  //насколько тут нужна эта проверка, если тянется с геттера
  if (!role) {
    role = localStorage.getItem('myRole') ?? 'unknown'
  }

  if (role == 'unknown') {
    if(to.query) {
      let utm = new URLSearchParams(to.query)
      next('/auth?' + utm.toString())
      return
    }

    next('/auth')
    return
  }

  next()
}

const ifNonAuth = (to, from, next) => {
  var role
  role = store.getters.getRole
  if (!role ||  role == 'unknown') localStorage.getItem('myRole') || 'unknown'
  if (role && role !== 'unknown') {
    next('/')
    return
  }
  next()
}


const routes = [
  {
    path: '/gm',
    name: 'GM',
    component: () => import('../views/gm/GMMapView.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/start',
    name: 'auth',
    component: () => import('../views/StartView.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/subdomains',
    name: 'subdomains',
    component: () => import('../views/SubdomainsView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'subdomains',
      label: 'АккаунтыIT',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'dev'
    },
  },
  {
    path: '/feeds',
    name: 'feeds',
    component: () => import('../views/FeedView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'feeds',
      label: 'Фиды',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'manage'
    },
  },
  {
    path: '/stats',
    name: 'stats',
    component: () => import('../views/StatsMainView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'stats',
      label: 'СтатистикаМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'manage'
    },
  },
  {
    path: '/deals',
    name: 'deals',
    component: () => import('../views/LeadsView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'deals',
      label: 'СделкиМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'clients'
    },
  },
  {
    path: '/deals-agent/:respId',
    name: 'deals-agent',
    component: () => import('../views/LeadsView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'deals',
      label: 'СделкиАгента',
      needAuth: true,
      hideAll: false,
      hideBar: false,
    },
    props: true
  },
/*  {
    path: '/board',
    name: 'home',
    component: () => import('../views/BoardView'),
    beforeEnter: ifAuth,
    meta: {
      // icon: 'board',
      // label: 'ШахматкаМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },*/
  {
    path: '/pipeline/2',
    name: 'pipeline',
    component: () => import('../views/LeadsView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'deals',
      label: 'СделкиБрокеридж',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'clients'
    },
  },
  {
    path: '/deals-it',
    name: 'deals-it',
    component: () => import('../views/LeadsView'),
    beforeEnter: ifAuth,
    meta: {
      // icon: 'deals',
      // label: 'СделкиITМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import('../views/TasksView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'tasks',
      label: 'ЗадачиМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/board-admin',
    name: 'home-admin',
    component: () => import('../views/BoardAdminView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'board',
      label: 'ШахматкаАдмина',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'manage'
    },
  },
  {
    path: '/brokeridge',
    name: 'brokeridge',
    component: () => import('../views/EstateMarkupView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'brokeridge',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      label: 'Брокеридж',
      category: 'objects'
    },
    props: {
      markupId: '18',
      isEditorDisable: true
    }
  },
  {
    path: '/parking/:developmentId?',
    name: 'parking_development',
    component: () => import('../views/EstateMarkupView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false,
    },
    props: (route) => ({
      isEditorDisable: false,
      category: 'parking',
      title: 'Парковки',
      developmentId: route.params.developmentId
    })
  },
  {
    path: '/parking/show/:markupId?',
    name: 'parking_one',
    component: () => import('../views/EstateMarkupView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false,
    },
    props: (route) => ({
      isEditorDisable: true,
      markupId: route.params.markupId
    })
  },
  {
    path: '/list-rooms',
    name: 'rooms',
    component: () => import('../views/Rooms/ListFlatsView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'list_rooms',
      label: 'Объявления',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'objects'
    },
  },
  {
    path: '/pantries',
    name: 'pantries',
    component: () => import('../views/Versions/PantriesVersions.vue'),
    beforeEnter: ifAuth,
    meta: {
      // icon: 'list_rooms',
      // label: 'Кладовки',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      // category: 'objects'
    },
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'board',
      label: 'ШахматкаМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'objects'
    },
  },
  {
    path: '/catalog/:subtype',
    name: 'catalog',
    component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'board',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/recent',
    name: 'Heart',
    component: () => import('../views/RecentView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'Heart',
      label: 'ИзбранныеОбъекты',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'objects'
    },
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('../views/RegistrationView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/selection',
    name: 'Bookmarks',
    component: () => import('../views/BookmarksView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'Bookmarks',
      label: 'ПодборкиМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'objects'
    },
  },
  {
    path: '/train',
    name: 'StudentTest',
    component: () => import('../views/StudentMainView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'educations',
      label: 'СистемаОбученияМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'profile'
    },
  },
  {
    path: '/doc-templates',
    name: 'doc-templates',
    component: () => import('../views/DocTemplatesView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'file',
      label: 'ШаблоныДокументов',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'profile'
    },
  },
/*  {
    path: '/mission-ava-3',
    name: 'mission-ava-3',
    component: () => import('../views/deprecatedViews/RatingView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'mission',
      label: 'КонкурсМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/competition',
    name: 'competition',
    component: () => import('../views/deprecatedViews/CompetitionView.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },*/
  {
    path: '/competition',
    name: 'competition',
    component: () => import('../views/Rating.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'mission',
      label: 'КонкурсМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
    },
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('../views/ShopView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'shop',
      label: 'Магазин',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/backlog',
    name: 'backlog',
    component: () => import('../views/BacklogView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'about',
      label: 'ОПриложении',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'profile'
    },
  },
  // {
  //   path: '/investments',
  //   name: 'investments',
  //   component: () => import('../../deprecated/deprecatedViews/InvestmentsView.vue'),
  //   beforeEnter: ifAuth,
  //   meta: {
  //     icon: 'investments',
  //     label: 'ЗемельныеУчастки',
  //     needAuth: true,
  //     hideAll: false,
  //     hideBar: false,
  //     category: 'objects'
  //   },
  // },
  // {
  //   path: '/kp',
  //   name: 'kp',
  //   component: () => import('../../deprecated/deprecatedViews/KpView.vue'),
  //   beforeEnter: ifAuth,
  //   meta: {
  //     icon: 'investments',
  //     label: 'Коттеджные поселки',
  //     needAuth: true,
  //     hideAll: false,
  //     hideBar: false,
  //     category: 'objects'
  //   },
  // },
  {
    path: '/events',
    name: 'events',
    component: () => import('../views/EventsView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'events',
      label: 'мероприятияМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'profile'
    },
  },
  {
    path: '/ambassador',
    name: 'ambassador',
    component: () => import('../views/ambassadorView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'ambassadors',
      label: 'Амбассадор',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'profile'
    },
  },
  {
    path: '/buildings',
    name: 'buildings',
    component: () => import('../views/BuildingsView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'control',
      label: 'УправлениеЖКМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'manage'
    },
  },
  {
    path: '/update-list',
    name: 'update-list',
    component: () => import('../views/UpdateView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'load_data',
      label: 'ЗагрузкаДанных',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'dev'
    },
  },
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import('../views/OrganizationsView'),
    beforeEnter: ifAuth,
    meta: {
      icon:'legal_entities',
      label: 'ЮрЛицаМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'manage'
    },
  },
  {
    path: '/agents',
    name: 'agents',
    component: () => import('../views/AgentsView'),
    beforeEnter: ifAuth,
    meta: {
      // icon:'agents',
      // label: 'АгентыМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      // category: 'manage'
    },
  },
  {
    path: '/agentsold',
    name: 'agentsold',
    component: () => import('../views/Agentsold.vue'),
    beforeEnter: ifAuth,
    meta: {
      // icon:'agents_sort',
      // label: 'АгентыСортировка',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/search-agents',
    name: 'searchAgents',
    component: () => import('../views/AgentsSearch.vue'),
    beforeEnter: ifAuth,
    meta: {
      // icon:'agents_search',
      // label: 'ПоискАгентов',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import('../views/ClientsView'),
    beforeEnter: ifAuth,
    meta: {
      // icon:'clients',
      // label: 'КлиентыМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/banks',
    name: 'banks',
    component: () => import('../views/BanksView'),
    beforeEnter: ifAuth,
    meta: {
      icon:'banks',
      label: 'БанкиМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'manage'
    },
  },
  {
    path: '/banks-table',
    name: 'bankst',
    component: () => import('../views/BanksTableView'),
    beforeEnter: ifAuth,
    meta: {
      // icon:'banks_table',
      // label: 'Банки(табл)Меню',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/discount-matrix',
    name: 'discount-matrix',
    component: () => import('../views/DiscountMatrixView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon:'discount_matrix',
      label: 'МатрицаСкидок',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/calculator-programs',
    name: 'calculatorPrograms',
    component: () => import('../views/CalculatorProgramsView'),
    beforeEnter: ifAuth,
    meta: {
      icon:'calculator_programs',
      label: 'Калькулятор:Программы',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'manage'
    },
  },
  {
    path: '/statuses',
    name: 'statuses',
    component: () => import('../views/StatusesView'),
    beforeEnter: ifAuth,
    meta: {
      icon:'funnel_statuses',
      label: 'СтатусыВоронкиМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'dev'
    },
  },
  {
    path: '/calculator_code_samples',
    name: 'calculator_code_samples',
    component: () => import('../views/CalculatorCodeSamplesView'),
    beforeEnter: ifAuth,
    meta: {
      icon:'list_rooms',
      label: 'Калькуляторы',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'dev'
    },
  },
  {
    path: '/statuses-sync',
    name: 'statusesSync',
    component: () => import('../views/StatusesSyncView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon:'statuses_sync',
      label: 'Статусы(синхр)Меню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'dev'
    },
  },
  {
    path: '/requests',
    name: 'requests',
    component: () => import('../views/RequestsView'),
    beforeEnter: ifAuth,
    meta: {
      label: 'ЗапросыМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/versions',
    name: 'versions',
    component: () => import('../views/VersionsView'),
    beforeEnter: ifAuth,
    meta: {
      label: 'ВерсииМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/stories',
    name: 'stories',
    component: () => import('../views/StoriesView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'stories',
      label: 'СторисыМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'manage'
    },
  },
  {
    path: '/actual-events',
    name: 'actual-events',
    component: () => import('../views/EventsListActual.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'events_management',
      label: 'МероприятияУправление',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'manage'
    },
  },
  {
    path: '/actual-news',
    name: 'actual-news',
    component: () => import('../views/NewsListActual.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'actual_news',
      label: 'Новости',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'manage'
    },
  },
  {
    path: '/stocks',
    name: 'stocks',
    component: () => import('../views/StocksView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'stocks',
      label: 'АкцииМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'manage'
    },
  },
  {
    path: '/educations',
    name: 'educations',
    component: () => import('../views/EducationsView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'educations',
      label: 'ОбучениеМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'dev'
    },
  },
  {
    path: '/triggers',
    name: 'triggers',
    component: () => import('../views/TriggersView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'triggers',
      label: 'ТриггерыМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'dev'
    },
  },
  {
    path: '/reviews-app',
    name: 'reviews-app',
    component: () => import('../views/ReviewsAppView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'reviews',
      label: 'отзывы',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'dev'
    },
  },
  {
    path: '/reviews-curator',
    name: 'reviews-curator',
    component: () => import('../views/ReviewsCuratorView.vue'),
    beforeEnter: ifAuth,
    meta: {
      label: 'ОтзывыОКураторах',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'manage'
    },
  },
  {
    path: '/reviews-object',
    name: 'reviews-object',
    component: () => import('../views/ReviewObjectView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'reviews',
      label: 'ОтзывыОбОбъектах',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'dev'
    },
  },
  {
    path: '/b24log',
    name: 'b24log',
    component: () => import('../views/BLogView'),
    beforeEnter: ifAuth,
    meta: {
      // label: 'Б24ошибкиМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/items',
    name: 'items',
    component: () => import('../views/ShopItemsView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'shop',
      label: 'Магазин',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'manage'
    },
  },
  {
    path: '/delete-users',
    name: 'deleteUsers',
    component: () => import('../views/DeleteUsers.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'testing',
      label: 'тестирование',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'dev'
    },
  },
  {
    path: '/banners',
    name: 'banners',
    component: () => import('../views/BanerView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'banners',
      label: 'Баннеры',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'manage'
    },
  },
  {
    path: '/integrations',
    name: 'integrations',
    component: () => import('../views/IntegrationsView'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'integrations',
      label: 'Интеграции',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'dev'
    },
  },
  {
    path: '/360',
    name: '360',
    component: () => import('../views/360.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'file',
      label: '360',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/agent-deals',
    name: 'agent-deals',
    component: () => import('../views/AgentDealsView.vue'),
    beforeEnter: ifAuth,
    meta: {
      // icon: 'funnel_agents',
      // label: 'ВоронкаАгентов',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },

  },
  {
    path: '/ambassadors',
    name: 'ambassadors',
    component: () => import('../views/AmbassadorsView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'ambassadors',
      label: 'Амбассадоры',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'manage'
    },
  },
  {
    path: '/estate-markup',
    name: 'estate-markup',
    component: () => import('../views/EstateMarkupView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'layout_markup',
      label: 'РазметкаПланировки',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'dev'
    },
  },
  {
    path: '/',
    name: 'main',
    component: () => import('../views/MainView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/testing-leads',
    name: 'testing-leads',
    component: () => import('../views/TestLeadsView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/ambassador-card/:id',
    name: 'ambassador-card',
    component: () => import('../views/ambassadorCurrentView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },

  // {
  //   path: '/investments/:id',
  //   name: 'investment',
  //   component: () => import('../../deprecated/deprecatedViews/InvestmentView.vue'),
  //   beforeEnter: ifAuth,
  //   meta: {
  //     needAuth: true,
  //     hideAll: false,
  //     hideBar: false
  //   },
  // },
/*  {
    path: '/chat',
    name: 'chat',
    component: () => import('../views/ChatReport'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/chat/:id',
    name: 'dialog',
    component: () => import('../components/dialogComponent'),
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },*/
/*  {
    path: '/commercial',
    name: 'commercial',
    component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
    beforeEnter: ifAuth,
    meta: {
      // icon: 'list_rooms',
      // label: 'Коммерция',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      // category: 'objects'
    },
  },*/
  {
    path: '/chess',
    name: 'chess',
    component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/chess-current',
    name: 'chess',
    component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/chess-current/:city',
    name: 'chess',
    component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/chess-current/:city/:complex',
    name: 'chess',
    component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/chess-current/:city/:complex/:liter',
    name: 'chess',
    component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
/*  {
    path: '/chess-current/:city/:complex/:liter',
    name: 'chess',
    component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },*/
  {
    path: '/chess-amo/:amo',
    name: 'chess',
    component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/chess/:deal',
    name: 'chess',
     component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/chess/:deal/:object',
    name: 'chess',
    component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/chess/:deal/:object/:crm',
    name: 'chess',
    component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },

  {
    path: '/chess-subtype/:subtype',
    name: 'chess-crm',
    component: () => import('../views/Pages/Catalog/RouterPagesCatalog.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import('../views/VIP/HeaderView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'vip',
      label: 'VIP',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/vip/:subtype',
    name: 'vip',
    component: () => import('../views/VIP/HeaderView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'vip',
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
/*  {
    path: '/objects',
    name: 'home',
    component: () => import('../views/HomeView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/list',
    name: 'chess',
    component: () => import('../views/HomeView'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },*/
  {
    path: '/object/:id',
    name: 'object',
    component: () => import('../views/ObjectView'),
    /*component: () => import('../views/Pages/ObjectCard/WrapperObjectView.vue'),*/
    //beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/small-object/:id',
    name: 'object',
    component: () => import('../views/SmallObject'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/object-ext/:extid/:element',
    name: 'object',
    component: () => import('../views/ObjectView'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/object/:id/:element',
    name: 'object',
    component: () => import('../views/ObjectView'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/object-ext/:extid',
    name: 'object',
    component: () => import('../views/ObjectView'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/object-trk/:id',
    name: 'object-trk',
    component: () => import('../views/ObjectView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/objects/:id',
    name: 'object',
    component: () => import('../views/ObjectView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/objects/:id/:deal',
    name: 'object',
    component: () => import('../views/ObjectView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },



  {
    path: '/archive-news',
    name: 'archive-news',
    component: () => import('../views/NewsListArchive.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/future-news',
    name: 'future-news',
    component: () => import('../views/NewsListFuture.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/actual-news/:id',
    name: 'actual-new',
    component: () => import('../views/NewsEditView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/news-answers/:id',
    name: 'news-answers',
    component: () => import('../views/NewsListAnswersView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/deals/create',
    name: 'createDeal',
    component: () => import('../views/LeadViewNew.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/deals/:id',
    name: 'deal',
    component: () => import('../views/LeadViewNew'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/deal/:id',
    name: 'iframe',
    component: () => import('../views/LeadViewNew'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/assistant',
    name: 'assistant',
    component: () => import('../views/VoiceRecognitionView.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/chats',
    name: 'chats',
    component: () => import('../views/ChatsView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/selection/:id',
    name: 'Bookmark',
    component: () => import('../views/BookmarkView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/object/:id',
    name: 'object',
    component: () => import('../views/ObjectView.vue'),
    //beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/visit/:hash',
    name: 'visit',
    component: () => import('../views/ObjectsView'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/attend/:hash',
    name: 'attend',
    component: () => import('../views/ObjectsCalcView'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/business-card/:hash',
    name: 'business-card',
    component: () => import('../views/CardBusinessSmallView.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/pdf/:hash',
    name: 'pdf',
    component: () => import('../views/ObjectsPDFView'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/certificate/:hash',
    name: 'certificate',
    component: () => import('../views/CertificatePDFView.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/trk/:hash',
    name: 'trk',
    component: () => import('../views/TrkPDFView.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
/*  {
    path: '/temporary-pdf/:hash',
    name: 'temporary-pdf',
    component: () => import('../views/TemporaryPDFView.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },*/
  {
    path: '/calc',
    name: 'calc',
    component: () => import('../components/redesign/calculator.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/calc/:id',
    name: 'calc',
    component: () => import('../components/redesign/calculator.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/reservation/:hash',
    name: 'reservation',
    component: () => import('../views/Pages/Reservation/ReservationView.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/reservation',
    name: 'reservation',
    component: () => import('../views/Pages/Reservation/ReservationView.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/mini-pdf/:hash',
    name: 'mini-pdf',
    component: () => import('../views/DefaultMiniPDFView.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/default-pdf/:hash',
    name: 'default-pdf',
    component: () => import('../views/Pages/PDF/PDFType.vue'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/buildings/:id',
    name: 'building',
    component: () => import('../views/BuildingView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/buildings/:complex/:id',
    name: 'building',
    component: () => import('../views/BuildView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  /*{
    path: '/buildings/:id/plan',
    name: 'buildingPlan',
    component: () => import('../views/BuildingPlanView'),
    beforeEnter: ifAuth
  },*/


  {
    path: '/feeds/:id',
    name: 'feeds',
    component: () => import('../views/Rooms/MainRoomsView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },


  {
    path: '/subdomains/:id',
    name: 'subdomains',
    component: () => import('../views/SubdomainView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/organizations/:id',
    name: 'organization',
    component: () => import('../views/OrganizationView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },

  {
    path: '/education/:id',
    name: 'education',
    component: () => import('../views/EducationView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },

  {
    path: '/education/:education/:id',
    name: 'question',
    component: () => import('../views/QuestionView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },



  {
    path: '/banks/:id',
    name: 'bank',
    component: () => import('../views/BankView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/banks/:id/programs/:program',
    name: 'program',
    component: () => import('../views/ProgramView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },

  {
    path: '/entrance',
    name: 'auth',
    component: () => import('../views/AuthView.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: false
    },
  },
  {
    path: '/invite/:hash',
    name: 'auth',
    // component: () => import('../views/auth')
    // component: () => import('../views/CompetitionView')
    component: () => import('../views/LoginView'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: false
    },
  },
  {
    path: '/partner/create',
    name: 'auth',
    component: () => import('../views/auth'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: false
    },
  },
  {
    path: '/partner',
    name: 'auth',
    component: () => import('../views/auth'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: false
    },
  },
  {
    path: '/partner/:hash',
    name: 'partnerLink',
    component: () => import('../views/PartnerLinkView'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/ambassador/:hash',
    name: 'auth',
    // component: () => import('../views/CompetitionView')
    component: () => import('../views/LoginView'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: false
    },
  },
  {
    path: '/left-menu-test',
    name: 'left-menu-test',
    // component: () => import('../views/CompetitionView')
    component: () => import('../components/navigation.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: false
    },
  },
  {
    path: '/curator/:hash',
    name: 'auth',
    // component: () => import('../views/CompetitionView')
    component: () => import('../views/LoginView'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: false
    },
  },
  {
    path: '/cashback/:hash',
    name: 'cashback',
    component: () => import('../views/cashbackLink'),
    meta: {
      needAuth: false,
      hideAll: false,
      hideBar: true
    },
  },
  {
    path: '/client/:data',
    name: 'auth',
    component: () => import('../views/autoAuth'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/client/:data/:lead',
    name: 'auth',
    component: () => import('../views/autoAuth'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/expo',
    name: 'auth',
    /*component: () => import('../views/LandingView')*/
    component: () => import('../views/LendingView.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
    /*component: () => import('../views/CompetitionView')*/
    // component: () => import('../views/auth')

  },
  {
    path: '/statuspage',
    name: 'auth',
    component: () => import('../views/StatusPageView.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/legacy',
    name: 'auth',
    component: () => import('../views/PrivacyPolicyView.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/security',
    name: 'auth',
    component: () => import('../views/SecurityView.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/privacy-personal-data',
    name: 'auth',
    component: () => import('../views/PersonalDataProcessingView.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/pay-info',
    name: 'auth',
    component: () => import('../views/PayInfo.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/auth',
    name: 'auth',
    // component: () => import('../views/LendingView.vue')
    component: () => import('../views/LoginView'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
    // component: () => import('../views/CompetitionView')
    // component: () => import('../views/auth')
  },
  {
    path: '/profile',
    name: 'profile',
  /*  component: () => import('../views/Pages/Profile/WrapperProfileView.vue'),*/
    component: () => import('../views/ProfileView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'User',
      label: 'МойПрофильМеню',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      category: 'profile'
    },
  },
  /*{
    path: '/profile/:tab',
    name: 'profile',
    component: () => import('../views/Pages/Profile/WrapperProfileView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/profile/groups/:groupId',
    name: 'profile',
    component: () => import('../views/Pages/Profile/EditorGroupsView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/profile-workers',
    name: 'profile',
    component: () => import('../views/Pages/Profile/WorkersView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/profile-user/:id',
    name: 'profile',
    component: () => import('../views/Pages/Profile/WrapperProfileView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/profile-user/:id/:tab',
    name: 'profile',
    component: () => import('../views/Pages/Profile/WrapperProfileView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/profile-user/:id/groups/:groupId',
    name: 'profile',
    component: () => import('../views/Pages/Profile/EditorGroupsView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/profile-workers/:id',
    name: 'profile',
    component: () => import('../views/Pages/Profile/WorkersView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },*/
  {
    path: '/profile/:id',
    name: 'profile',
    component: () => import('../views/ProfileView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/profile/:id/menu',
    name: 'profile',
    component: () => import('../views/ProfileMenuView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/employee',
    name: 'employee',
    component: () => import('../views/EmployeeView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/calculator/:sum/:square',
    name: 'calculator',
    component: () => import('../views/calculator'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: false
    },
  },
  {
    path: '/calculator/:sum',
    name: 'calculator',
    component: () => import('../views/calculator'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: false
    },
  },
  {
    path: '/calculator',
    name: 'calculator',
    component: () => import('../views/calculator'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: false
    },
  },
  {
    path: '/statuses/:id',
    name: 'status',
    component: () => import('../views/StatusView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },

  {
    path: '/statuses-sync/:id',
    name: 'statusesSync',
    component: () => import('../views/StatusSyncView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/changelog',
    name: 'changelog',
    component: () => import('../views/ChangelogView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },

  {
    path: '/editor',
    name: 'editor',
    component: () => import('../views/ConstructorView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/my',
    name: 'personal',
    component: () => import('../views/PersonalConstructorView'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: false
    },
  },
  {
    path: '/u/:link',
    name: 'personal',
    component: () => import('../views/PersonalConstructorView'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: false
    },
  },
  {
    path: '/requests/:id',
    name: 'request',
    component: () => import('../views/RequestView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },

  {
    path: '/versions/:id',
    name: 'version',
    component: () => import('../views/VersionView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },

  {
    path: '/items/:id',
    name: 'item',
    component: () => import('../views/ShopItemView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },

  {
    path: '/buys/:id',
    name: 'buy',
    component: () => import('../views/BuyView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/buys',
    name: 'buys',
    component: () => import('../views/BuysView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/archive-buys',
    name: 'archive-buys',
    component: () => import('../views/BuysArchiveView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/markup/:id',
    name: 'markup',
    component: () => import('../views/MarkupView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/discount/:id',
    name: 'discount',
    component: () => import('../views/DiscountView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/repair/:id',
    name: 'markup',
    component: () => import('../views/RepairView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/packet/:id',
    name: 'markup',
    component: () => import('../views/PacketView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/profit/:id',
    name: 'profit',
    component: () => import('../views/ProfitView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/stocks/:id',
    name: 'stocks',
    component: () => import('../views/StockView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },

  {
    path: '/calculator-programs/:id',
    name: 'calculatorPrograms',
    component: () => import('../views/CalculatorProgramView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/triggers/:id',
    name: 'triggers',
    component: () => import('../views/TriggerView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },

  {
    path: '/video-card/:hash',
    name: 'video-card',
    component: () => import('../components/videoComponent.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
  {
    path: '/create',
    name: 'rooms',
    component: () => import('../views/Rooms/MainRoomsView.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },

  {
    path: '/create-object/:id?',
    name: 'rooms',
    component: () => import('../views/Rooms/CreateObjectView.vue'),
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
    props: true
  },

  {
    path: '/my-object',
    name: 'rooms',
    component: () => import('../views/Rooms/CreateObjectView.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },

  {
    path: '/events/:id',
    name: 'events',
    component: () => import('../views/EventView'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/event-card/:id',
    name: 'event-card',
    component: () => import('../views/EventCardView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/archive-events',
    name: 'archive-events',
    component: () => import('../views/EventsListArchive.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },

  {
    path: '/actual-events/:id',
    name: 'actual-events',
    component: () => import('../views/EventsEditView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/actual-events-new',
    name: 'actual-events-new',
    component: () => import('../views/EventsNewView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },


  {
    path: '/banner/:id',
    name: 'banner',
    component: () => import('../views/BannerView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },



  {
    path: '/agent-deal/:id',
    name: 'agent-deal',
    component: () => import('../views/AgentDealItemView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },

  {
    path: '/estate-markup-editor/:id',
    name: 'estate-markup-editor',
    component: () => import('../views/EstateMarkupEditorView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/doc-editor/:id',
    name: 'generated-docs-editor',
    component: () => import('../views/GeneratedDocsEditorView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/test-push',
    name: 'test-push',
    component: () => import('../views/TestPushView.vue'),
    beforeEnter: ifAuth,
    meta: {
      // icon: 'brokeridge',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      // label: 'Отправка пуш-уведомлений',
      // category: 'dev'
    },
  },
  {
    path: '/bloc-renovations',
    name: 'bloc-renovations',
    component: () => import('../views/BLOC/BlocRenovationsView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'brokeridge',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      label: 'Ремонты',
      category: 'manage'
    },
  },
  {
    path: '/agent-list',
    name: 'agent-list',
    component: () => import('../views/AgentListView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'profile',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      label: 'Управление пользователями',
      category: 'dev'
    },
  },
  {
    path: '/check-history',
    name: 'check-history',
    component: () => import('../views/CheckHistoryView.vue'),
    beforeEnter: ifAuth,
    meta: {
      icon: 'brokeridge',
      needAuth: true,
      hideAll: false,
      hideBar: false,
      label: 'История проверок',
    },
  },
  {
    path: '/bloc-renovations/edit/:id?',
    name: 'bloc-renovations',
    component: () => import('../views/BLOC/BlocRenovationEditView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/bloc-renovations/edit-furniture/:id?',
    name: 'bloc-renovations',
    component: () => import('../views/BLOC/blocFurnitureEditView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/bloc-renovations/edit-discount/:id?',
    name: 'bloc-renovations',
    component: () => import('../views/BLOC/blocDiscountEditView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/bloc-renovations/edit-package/:id?',
    name: 'bloc-renovations',
    component: () => import('../views/BLOC/blocPackageEditView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  {
    path: '/bloc-renovations/edit-color/:id?',
    name: 'bloc-renovations',
    component: () => import('../views/BLOC/blocColorEditView.vue'),
    beforeEnter: ifAuth,
    meta: {
      needAuth: true,
      hideAll: false,
      hideBar: false
    },
  },
  // {
  //   path: '/actual-news/:id',
  //   name: 'actual-new',
  //   component: () => import('../views/NewsEditView.vue'),
  //   beforeEnter: ifAuth
  // },
  {
    path: '/avto-mall',
    name: 'avto-mall',
    component: () => import('../views/Pages/AvtoMall/AvtoMall.vue'),
    meta: {
      needAuth: false,
      hideAll: true,
      hideBar: true
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
